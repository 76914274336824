<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">運転免許の一括作成</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <a-steps :current="current">
                <a-step v-for="item in steps" :key="item.title" :title="item.title" />
              </a-steps>
              <div style="margin-top: 40px; padding-bottom: 30px;">
                <div v-if="current === 0">
                  <p>テンプレートファイルをダウンロードし、作成したい運転免許をエクセルにて入力してください。作成しましたらページ下部よりファイルをアップロードしてください。</p>
                  <a-card title="運転免許の一括作成テンプレート" style="margin-bottom: 30px;">
                    <p class="text-center" style="font-size: 1.2em"><a href="templates/driver_lisence_template.xlsx" class="text-primary"><i class="fa fa-file-excel-o" /> テンプレートファイル</a></p>
                    <p>
                      1行目は変更しないでください。<br>
                      2行目はサンプルとしていれているので、2行目から書き換えてお使いください。<br><br>
                      ※<strong>ユーザーID</strong>は必須項目で、ユーザー一覧のCSV出力に記載されている「id」を入力してください。<br>
                      <strong>免許取得年月日、免許証有効期限年月日</strong>は任意項目で、YYYY-MM-DD の形式(例. 2022年2月1日の場合、2022-02-01)で入力してください<br>
                      以下項目の選択肢についてです。それぞれ数値を入力してください。<br>
                      <strong>免許種類</strong>は [1: 普通（第一種）, 2: 普通（第二種）, 3: 準中型, 4: 中型（第一種）, 5: 中型（第二種）, 6: 大型（第一種）, 7: 大型（第二種）, 8: 小型特殊, 9: 大型特殊（第一種）, 10: 大型特殊（第二種）, 11: 牽引（第一種）, 12: 牽引（第二種）, 13: 普通二輪, 14: 大型二輪, 15: 原付]<br>
                    </p>
                  </a-card>
                  <a-upload-dragger
                    name="file"
                    :multiple="false"
                    :beforeUpload="beforeUpload"
                  >
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">
                      クリックしてファイルを選択 または ファイルをドラッグ してください。
                    </p>
                    <p class="ant-upload-hint">
                      ファイルはテンプレートに沿って作られたものに限ります。
                    </p>
                  </a-upload-dragger>
                </div>
                <div v-if="current === 1">
                  <h5 v-if="errorCount" class="text-danger" style="margin-bottom: 20px;">入力にエラーがあります。背景に<span class="wrongCell">色がついているセル</span>を確認してください。</h5>
                  <a-table
                    :columns="columns"
                    :data-source="excelData"
                    :row-key="record => record.name + record.number"
                    :pagination="false"
                    style="margin-bottom: 30px;"
                  >
                    <span slot="user_id" slot-scope="user_id">{{ outputUserName(user_id) }}</span>
                    <span slot="number" slot-scope="number">{{ number }}</span>
                    <span slot="types" slot-scope="types">{{ typesName(types) }}</span>
                    <span slot="condition" slot-scope="condition">{{ condition }}</span>
                    <span slot="date_acquisition" slot-scope="date_acquisition">{{ date_acquisition }}</span>
                    <span slot="date_expiration" slot-scope="date_expiration">{{ date_expiration }}</span>
                    <span slot="address" slot-scope="address">{{ address }}</span>
                    <span slot="emergency_contact_full_name" slot-scope="emergency_contact_full_name">{{ emergency_contact_full_name }}</span>
                    <span slot="emergency_contact_relation_ship" slot-scope="emergency_contact_relation_ship">{{ emergency_contact_relation_ship }}</span>
                    <span slot="emergency_contact_tel" slot-scope="emergency_contact_tel">{{ emergency_contact_tel }}</span>
                  </a-table>
                  <p v-if="!errorCount">以上のデータで作成してよろしいですか？</p>
                  <div class="steps-action">
                    <a-button v-if="!errorCount" type="primary" @click="createDriverLisence" :loading="createLoading">
                      作成する
                    </a-button>
                    <a-button style="margin-left: 8px" @click="prev">
                      戻る
                    </a-button>
                  </div>
                </div>
                <div v-if="current === 2">
                  <p>2番目</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import XLSX from 'xlsx'
import { enumData } from '@/services/enum'

export default {
  data() {
    return {
      current: 0,
      createLoading: false,
      lisenceTypes: enumData.driversLisenceType,
      types: [],
      steps: [
        { title: 'エクセルでデータを作成' },
        { title: 'データの確認' },
        { title: '作成' },
      ],
      columns: [
        {
          title: 'ユーザーID',
          dataIndex: 'ユーザーID',
          scopedSlots: { customRender: 'user_id' },
          customCell: (text, row, index) => {
            if (!this.checkUserId(text['ユーザーID'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '免許証番号',
          dataIndex: '免許証番号',
          scopedSlots: { customRender: 'number' },
          customCell: (text, row, index) => {
            if (!this.checkNumber(text['免許証番号'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '免許種類',
          dataIndex: '免許種類',
          scopedSlots: { customRender: 'types' },
          customCell: (text, row, index) => {
            if (!this.checkTypes(text['免許種類'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '免許条件',
          dataIndex: '免許条件',
          scopedSlots: { customRender: 'condition' },
          customCell: (text, row, index) => {
            if (!this.checkCondition(text['免許条件'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '免許取得年月日',
          dataIndex: '免許取得年月日',
          scopedSlots: { customRender: 'date_acquisition' },
          customCell: (text, row, index) => {
            if (!this.checkDateAcquisition(text['免許取得年月日'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '免許証有効期限年月日',
          dataIndex: '免許証有効期限年月日',
          scopedSlots: { customRender: 'date_expiration' },
          customCell: (text, row, index) => {
            if (!this.checkDateExpiration(text['免許証有効期限年月日'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '住所',
          dataIndex: '住所',
          scopedSlots: { customRender: 'address' },
          customCell: (text, row, index) => {
            if (!this.checkAddress(text['住所'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '緊急連絡先：氏名',
          dataIndex: '緊急連絡先：氏名',
          scopedSlots: { customRender: 'emergency_contact_full_name' },
          customCell: (text, row, index) => {
            if (!this.checkEmergencyContactFullName(text['緊急連絡先：氏名'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '緊急連絡先：関係',
          dataIndex: '緊急連絡先：関係',
          scopedSlots: { customRender: 'emergency_contact_relation_ship' },
          customCell: (text, row, index) => {
            if (!this.checkEmergencyContactRelationShip(text['緊急連絡先：関係'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '緊急連絡先：TEL',
          dataIndex: '緊急連絡先：TEL',
          scopedSlots: { customRender: 'emergency_contact_tel' },
          customCell: (text, row, index) => {
            if (!this.checkEmergencyContactTel(text['緊急連絡先：TEL'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
      ],
      excelData: null,
      errorCount: 0,
      users: [],
    }
  },
  watch: {
    excelData: function(val) {
      const _this = this
      for (const row in val) {
        if (!_this.checkUserId(val[row]['ユーザーID'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkNumber(val[row]['免許証番号'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkTypes(val[row]['免許種類'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkCondition(val[row]['免許条件'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkDateAcquisition(val[row]['免許取得年月日'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkDateExpiration(val[row]['免許証有効期限年月日'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkAddress(val[row]['住所'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkEmergencyContactFullName(val[row]['緊急連絡先：氏名'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkEmergencyContactRelationShip(val[row]['緊急連絡先：関係'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkEmergencyContactTel(val[row]['緊急連絡先：TEL'])) {
          _this.errorCount++
          break
        }
      }
    },
  },
  created() {
    const userList = Vue.prototype.$api.send('get', 'users_for_list')
    userList.then(response => {
      this.users = response
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': ユーザー一覧の取得に失敗しました。',
        })
        this.userListLoading = false
      })
  },
  methods: {
    typesName(types) {
      if (types) {
        const typeArr = String(types).split(',')
        let rtnVal = ''
        for (let i = 0; i < typeArr.length; i++) {
          const type = this.lisenceTypes.find((l) => l.value === String(typeArr[i]))
          if (type) {
            rtnVal += type.label + ','
          } else {
            rtnVal = '使用できない免許種類が含まれています。'
            break
          }
        }
        return rtnVal
      } else {
        return ''
      }
    },
    next() {
      this.current++
    },
    prev() {
      this.current--
    },
    beforeUpload(file) {
      const _this = this
      _this.errorCount = 0
      console.log(file)
      const reader = new FileReader()
      reader.onload = function(file) {
        const data = new Uint8Array(file.target.result)
        const workbook = XLSX.read(data, { type: 'array' })
        console.log(workbook)
        _this.excelData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
      }
      reader.readAsArrayBuffer(file)
      this.current++
      return false
    },
    handleChange(info) {
      const status = info.file.status
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    outputUserName(val) {
      const user = this.users.find((e) => e.id === val)
      return user ? user.name : '見つかりません。'
    },
    checkUserId(val) {
      if (val && Number.isInteger(val) && this.users.find((e) => e.id === val)) {
        return true
      }
      return false
    },
    checkNumber(val) { return !val || val.length < 256 },
    checkTypes(val) {
      const reg = /^[0-9]+(,[0-9]+)*$/
      if (!reg.test(val)) {
        return false
      }
      const typeArr = String(val).split(',')
      for (let i = 0; i < typeArr.length; i++) {
        const type = this.lisenceTypes.find((l) => l.value === String(typeArr[i]))
        if (!type) return false
      }
      return true
    },
    checkCondition(val) { return !val || val.length < 1024 },
    checkDateAcquisition(val) {
      const reg = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
      if (val && !reg.test(val)) {
        return false
      }
      return true
    },
    checkDateExpiration(val) {
      const reg = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
      if (!val || !reg.test(val)) {
        return false
      }
      return true
    },
    checkAddress(val) { return !val || val.length < 1024 },
    checkEmergencyContactFullName(val) { return !val || val.length < 1024 },
    checkEmergencyContactRelationShip(val) { return !val || val.length < 1024 },
    checkEmergencyContactTel(val) { return !val || val.length < 1024 },
    createDriverLisence() {
      this.createLoading = true
      const result = Vue.prototype.$api.send('post', 'drivers_lisences/bulk', { drivers_lisences: this.excelData })
      result.then(response => {
        this.$notification['success']({
          message: '運転免許を作成しました',
        })
        this.$router.push('/company')
        this.createLoading = false
      })
        .catch(error => {
          let duration = 4.5
          if (error.status === 500) {
            duration = 15
          }
          this.$notification['error']({
            message: error.status + ': ' + error.data.data,
            duration: duration,
          })
          this.createLoading = false
        })
    },
  },
}
</script>

<style>
.wrongCell {
  background-color: #FED1D1;
}
</style>
