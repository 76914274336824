var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              [
                _c(
                  "a-steps",
                  { attrs: { current: _vm.current } },
                  _vm._l(_vm.steps, function(item) {
                    return _c("a-step", {
                      key: item.title,
                      attrs: { title: item.title }
                    })
                  }),
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-top": "40px",
                      "padding-bottom": "30px"
                    }
                  },
                  [
                    _vm.current === 0
                      ? _c(
                          "div",
                          [
                            _c("p", [
                              _vm._v(
                                "テンプレートファイルをダウンロードし、作成したい運転免許をエクセルにて入力してください。作成しましたらページ下部よりファイルをアップロードしてください。"
                              )
                            ]),
                            _c(
                              "a-card",
                              {
                                staticStyle: { "margin-bottom": "30px" },
                                attrs: {
                                  title: "運転免許の一括作成テンプレート"
                                }
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass: "text-center",
                                    staticStyle: { "font-size": "1.2em" }
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-primary",
                                        attrs: {
                                          href:
                                            "templates/driver_lisence_template.xlsx"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-file-excel-o"
                                        }),
                                        _vm._v(" テンプレートファイル")
                                      ]
                                    )
                                  ]
                                ),
                                _c("p", [
                                  _vm._v(
                                    "\n                    1行目は変更しないでください。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                    2行目はサンプルとしていれているので、2行目から書き換えてお使いください。"
                                  ),
                                  _c("br"),
                                  _c("br"),
                                  _vm._v("\n                    ※"),
                                  _c("strong", [_vm._v("ユーザーID")]),
                                  _vm._v(
                                    "は必須項目で、ユーザー一覧のCSV出力に記載されている「id」を入力してください。"
                                  ),
                                  _c("br"),
                                  _c("strong", [
                                    _vm._v(
                                      "免許取得年月日、免許証有効期限年月日"
                                    )
                                  ]),
                                  _vm._v(
                                    "は任意項目で、YYYY-MM-DD の形式(例. 2022年2月1日の場合、2022-02-01)で入力してください"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                    以下項目の選択肢についてです。それぞれ数値を入力してください。"
                                  ),
                                  _c("br"),
                                  _c("strong", [_vm._v("免許種類")]),
                                  _vm._v(
                                    "は [1: 普通（第一種）, 2: 普通（第二種）, 3: 準中型, 4: 中型（第一種）, 5: 中型（第二種）, 6: 大型（第一種）, 7: 大型（第二種）, 8: 小型特殊, 9: 大型特殊（第一種）, 10: 大型特殊（第二種）, 11: 牽引（第一種）, 12: 牽引（第二種）, 13: 普通二輪, 14: 大型二輪, 15: 原付]"
                                  ),
                                  _c("br")
                                ])
                              ]
                            ),
                            _c(
                              "a-upload-dragger",
                              {
                                attrs: {
                                  name: "file",
                                  multiple: false,
                                  beforeUpload: _vm.beforeUpload
                                }
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "ant-upload-drag-icon" },
                                  [_c("a-icon", { attrs: { type: "inbox" } })],
                                  1
                                ),
                                _c("p", { staticClass: "ant-upload-text" }, [
                                  _vm._v(
                                    "\n                    クリックしてファイルを選択 または ファイルをドラッグ してください。\n                  "
                                  )
                                ]),
                                _c("p", { staticClass: "ant-upload-hint" }, [
                                  _vm._v(
                                    "\n                    ファイルはテンプレートに沿って作られたものに限ります。\n                  "
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.current === 1
                      ? _c(
                          "div",
                          [
                            _vm.errorCount
                              ? _c(
                                  "h5",
                                  {
                                    staticClass: "text-danger",
                                    staticStyle: { "margin-bottom": "20px" }
                                  },
                                  [
                                    _vm._v("入力にエラーがあります。背景に"),
                                    _c("span", { staticClass: "wrongCell" }, [
                                      _vm._v("色がついているセル")
                                    ]),
                                    _vm._v("を確認してください。")
                                  ]
                                )
                              : _vm._e(),
                            _c("a-table", {
                              staticStyle: { "margin-bottom": "30px" },
                              attrs: {
                                columns: _vm.columns,
                                "data-source": _vm.excelData,
                                "row-key": function(record) {
                                  return record.name + record.number
                                },
                                pagination: false
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "user_id",
                                    fn: function(user_id) {
                                      return _c("span", {}, [
                                        _vm._v(
                                          _vm._s(_vm.outputUserName(user_id))
                                        )
                                      ])
                                    }
                                  },
                                  {
                                    key: "number",
                                    fn: function(number) {
                                      return _c("span", {}, [
                                        _vm._v(_vm._s(number))
                                      ])
                                    }
                                  },
                                  {
                                    key: "types",
                                    fn: function(types) {
                                      return _c("span", {}, [
                                        _vm._v(_vm._s(_vm.typesName(types)))
                                      ])
                                    }
                                  },
                                  {
                                    key: "condition",
                                    fn: function(condition) {
                                      return _c("span", {}, [
                                        _vm._v(_vm._s(condition))
                                      ])
                                    }
                                  },
                                  {
                                    key: "date_acquisition",
                                    fn: function(date_acquisition) {
                                      return _c("span", {}, [
                                        _vm._v(_vm._s(date_acquisition))
                                      ])
                                    }
                                  },
                                  {
                                    key: "date_expiration",
                                    fn: function(date_expiration) {
                                      return _c("span", {}, [
                                        _vm._v(_vm._s(date_expiration))
                                      ])
                                    }
                                  },
                                  {
                                    key: "address",
                                    fn: function(address) {
                                      return _c("span", {}, [
                                        _vm._v(_vm._s(address))
                                      ])
                                    }
                                  },
                                  {
                                    key: "emergency_contact_full_name",
                                    fn: function(emergency_contact_full_name) {
                                      return _c("span", {}, [
                                        _vm._v(
                                          _vm._s(emergency_contact_full_name)
                                        )
                                      ])
                                    }
                                  },
                                  {
                                    key: "emergency_contact_relation_ship",
                                    fn: function(
                                      emergency_contact_relation_ship
                                    ) {
                                      return _c("span", {}, [
                                        _vm._v(
                                          _vm._s(
                                            emergency_contact_relation_ship
                                          )
                                        )
                                      ])
                                    }
                                  },
                                  {
                                    key: "emergency_contact_tel",
                                    fn: function(emergency_contact_tel) {
                                      return _c("span", {}, [
                                        _vm._v(_vm._s(emergency_contact_tel))
                                      ])
                                    }
                                  }
                                ],
                                null,
                                false,
                                139415588
                              )
                            }),
                            !_vm.errorCount
                              ? _c("p", [
                                  _vm._v(
                                    "以上のデータで作成してよろしいですか？"
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "steps-action" },
                              [
                                !_vm.errorCount
                                  ? _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          loading: _vm.createLoading
                                        },
                                        on: { click: _vm.createDriverLisence }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    作成する\n                  "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-left": "8px" },
                                    on: { click: _vm.prev }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    戻る\n                  "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.current === 2
                      ? _c("div", [_c("p", [_vm._v("2番目")])])
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "utils__title mb-3" }, [
      _c("strong", { staticClass: "text-uppercase font-size-16" }, [
        _vm._v("運転免許の一括作成")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }